<template>
  <div
    id="four-oh-four"
    class="container-fluid d-flex flex-column align-items-center"
  >
    <div cols="12" class="super-headline3-light text-center">
      {{ $t("404.headline") }}
    </div>
    <div id="plundered-chest"></div>
    <span class="404-subheadline bold text-white text-uppercase">{{
      $t("404.subheadline")
    }}</span>
    <span class="404-text text-white text-uppercase">{{ $t("404.text") }}</span>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  metaInfo() {
    return {
      title: "Seite nicht gefunden",
      titleTemplate: "%s | Kleine Krone",
    };
  },

  mounted() {
    this.$store.commit("setIsPageLoading", false);
  },
};
</script>
<style scoped lang="scss">
#four-oh-four {
  height: 100vh;
  .super-headline3-light {
    font-size: 48px;
  }
  #plundered-chest {
    width: 496px;
    height: 316px;
    background: url(~@/assets/img/backgrounds/404-rats.png) center/100% 100%
      no-repeat;
  }
  span {
    text-shadow: 0 0 16px rgba(77, 229, 255, 0.7), 0 0 8px $light-blue,
      0 0 4px rgba(18, 115, 132, 0.7);
    font-size: 18px;
    &:first-of-type {
      font-size: 21px;
    }
  }
}
</style>
